import { API } from "@project/shared"
import { IUserList, IUserManagement } from "../../interface/user-management"

export const CreateUser = (data) => {
  return API.post(`/staffs`, data)
}

export const GetAllUser = async ({ queryKey }): Promise<IUserList> => {
  const page = queryKey[1]
  const limit = queryKey[2]
  const searchQuery = queryKey[3]
  const facility_id = queryKey[4]
  const res = await API.get<any>(`/staffs`, {
    params: {
      page,
      limit,
      keyword: searchQuery,
      facility_id,
    },
  })
  return res
}

export const getOneUser = async (id: string): Promise<IUserManagement> => {
  const { data } = await API.get<IUserManagement>(`/staffs/${id}`)
  return data
}

export const updateUser = async (data, id): Promise<any> => {
  return API.put(`/staffs/${id}`, data)
}

export const getFacilityUser = async (id: string): Promise<IUserManagement> => {
  const { data } = await API.get<IUserManagement>(`/facilities/${id}`)
  return data
}

export const updateFacilityUser = async (data, id): Promise<any> => {
  return API.put(`/facilities/${id}`, data)
}

export const updateStaffPassword = async (
  data: { password: string },
  id: number
): Promise<any> => {
  return API.post("staffs/change_password", { password: data?.password, id })
}
