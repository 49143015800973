import React, { useContext, useEffect, useState } from "react"
import Router, { useRouter } from "next/router"
import { notification, Spin } from "antd"
import styled from "styled-components"
import { AuthContext } from "./utils/AuthContext"
import { useTranslation } from "react-i18next"
import { SideBar } from "./components"

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
`

const PrivateRoute = (AuthComponent) => {
  function PrivateComponent({ children }) {
    const router = useRouter()
    const { t } = useTranslation()
    const { authenticated, loading, user } = useContext(AuthContext)
    const { pathname } = router
    useEffect(() => {
      if (!loading) {
        if (!authenticated) {
          router.push("/login")
        }
      }
      if (authenticated && pathname === "/") {
        router.push("/home")
      }
    }, [authenticated, loading, user])
    if (loading) {
      return (
        <LoaderWrapper>
          <Spin size={"large"} className={"loader"} />
        </LoaderWrapper>
      )
    }
    return (
      <>
        {authenticated && (
          <>
            {" "}
            <SideBar>{children}</SideBar>{" "}
          </>
        )}{" "}
      </>
    )
  }

  return class Higher extends React.Component {
    render() {
      return (
        <PrivateComponent>
          <AuthComponent {...this.props} />
        </PrivateComponent>
      )
    }
  }
}

export default PrivateRoute
