import { Button, InputField, theme, SelectComponent } from "@project/shared"
import { useContext, useRef, useState } from "react"
import { FocusError } from "focus-formik-error"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import * as yup from "yup"
import { PlusOutlined } from "@ant-design/icons"
import { Divider, Space, Spin, notification } from "antd"
import { UseMutateFunction, useMutation, useQuery } from "react-query"
import { ProductListInterface } from "../../../interface"
import {
  createProductCategories,
  featchProductCategories,
} from "../../../services"
import { AuthContext } from "../../../utils"
import { HtmlType } from "@project/shared/src/constants"

interface ProductFormProps {
  isLoading?: boolean
  onSubmit?: UseMutateFunction<
    any,
    unknown,
    ProductListInterface | void,
    unknown
  >
  data?: ProductListInterface
  detailLoading?: boolean
}
const Container = styled.div`
  background-color: ${theme.background.colorSecondary3};
  .container {
    margin: 35px;
    border: solid 1px ${theme.border.colorSecondary1};
    padding: 20px;
    border-radius: 5px;
    background: ${theme.background.colorSecondary1};
  }
  .label {
    ${theme.typography.subLabel};
  }
  .error {
    ${theme.typography.subLabel};
    color: ${theme.font.colorAlert};
  }
  .field-wrapper {
    margin-bottom: 10px;
  }
  .button-container {
    margin-top: 30px;
    display: flex;
    justify-content: end;
  }
`

const ProductForm: React.FC<ProductFormProps> = (ProductFormProps) => {
  const { isLoading, onSubmit, data, detailLoading } = ProductFormProps
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const [open, setOpen] = useState(false)
  const [newCategory, setNewCategory] = useState("")
  const [required, setRequired] = useState(false)
  const facility_id = user["custom:facilityId"]

  const validationSchema = yup.object().shape({
    product_category_id: yup
      .string()
      .nullable()
      .required(
        `${t("inputValidationMessage", {
          fieldName: `${t("Category")}`,
        })}`
      ),
    name: yup.string().required(
      `${t("inputValidationMessage", {
        fieldName: `${t("Product name")}`,
      })}`
    ),
    long_description: yup.string().required(
      `${t("inputValidationMessage", {
        fieldName: `${t("Long Description")}`,
      })}`
    ),
    regular_price: yup
      .number()
      .typeError(t("Price should be a number"))
      .required(
        `${t("inputValidationMessage", {
          fieldName: `${t("Listing Price")}`,
        })}`
      ),
    selling_price: yup
      .number()
      .typeError(t("Price should be a number"))
      .required(
        `${t("inputValidationMessage", {
          fieldName: `${t("Selling Price")}`,
        })}`
      ),
  })

  const initialValues = data
    ? data
    : ({
        product_category_id: "",
        name: "",
        short_description: "",
        long_description: "",
        regular_price: "",
        selling_price: "",
      } as ProductListInterface)

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      onSubmit({
        ...values,
      })
    },
  })

  const {
    isLoading: categoryListLoading,
    isFetching,
    data: searchData,
    refetch,
  } = useQuery(
    ["product-categories", 1, 20, facility_id],
    featchProductCategories,
    {
      refetchOnWindowFocus: false,
      enabled: true,
    }
  )

  const { mutate, isLoading: loadingProductCat } = useMutation(
    createProductCategories,
    {
      onSuccess: ({ data }) => {
        notification.success({
          message: t("Successfully Created Products Category"),
        })
        refetch().then(() => {
          setOpen(true)
          formik.setFieldValue("product_category_id", data?.id)
        })
      },
      onError: (error?: any) => {
        notification.error({
          message: t("Something Went Wrong while Creating Product"),
        })
      },
    }
  )

  const onCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequired(false)
    setNewCategory(event.target.value)
    if (event.target.value === "") {
      setOpen(true)
      setRequired(true)
    }
  }
  const createCategory = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault()
    if (newCategory === "") {
      setOpen(true)
      setRequired(true)
      return
    }
    setRequired(false)
    mutate({
      name: newCategory,
    })
    setNewCategory("")
  }
  if (detailLoading) {
    return (
      <div style={{ textAlign: "center", padding: 300 }}>
        <Spin />
      </div>
    )
  }

  return (
    <Container>
      <div className={"container"}>
        <form onSubmit={formik.handleSubmit}>
          <FocusError formik={formik} />
          <div className={"field-wrapper"}>
            <SelectComponent
              label={t("Category")}
              placeholder={t("Category")}
              name={"product_category_id"}
              error={
                formik.touched.product_category_id &&
                (formik.errors.product_category_id as string)
              }
              {...formik.getFieldProps("product_category_id")}
              value={formik.values.product_category_id}
              options={searchData?.data.map((data) => {
                return {
                  key: data.id,
                  value: data.id,
                  label: data.name,
                }
              })}
              disabled={false}
              optionFilterProp={"label"}
              loading={categoryListLoading || isFetching}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space
                    style={{
                      padding: "0 8px 4px",
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    <InputField
                      placeholder={t("Please enter category")}
                      value={newCategory}
                      onChange={onCategoryChange}
                      height={"40px"}
                      required
                      maxLength={12}
                      errorClassName={"error"}
                      error={required && t("Please enter category")}
                      showCounting
                    />

                    <Button
                      type={"business"}
                      label={t("Add Category")}
                      icon={<PlusOutlined />}
                      onClick={createCategory}
                      borderRadius={"10px"}
                      height={"40px"}
                      loading={loadingProductCat}
                    />
                  </Space>
                </>
              )}
              open={open}
              onDropdownVisibleChange={(
                visible: boolean | ((prevState: boolean) => boolean)
              ) => setOpen(visible)}
              onChange={(value) => {
                formik.setFieldValue("product_category_id", value)
              }}
              onBlur={formik.handleBlur}
              labelClassName={"label"}
              showSearch={true}
              required
            />
          </div>
          <div className={"field-wrapper"}>
            <InputField
              label={t("Product name")}
              placeholder={t("Product name")}
              name={"name"}
              errorClassName={"error"}
              height={"42px"}
              error={formik.touched.name && formik.errors.name}
              {...formik.getFieldProps("name")}
              labelClassName={"label"}
              maxLength={12}
              showCounting
              required
            />
          </div>
          <div className={"field-wrapper"}>
            <InputField
              label={t("Short Description")}
              name={"short_description"}
              errorClassName={"error"}
              height={"42px"}
              error={
                formik.touched.short_description &&
                formik.errors.short_description
              }
              {...formik.getFieldProps("short_description")}
              labelClassName={"label"}
              maxLength={12}
              showCounting
              placeholder={t("Short Description")}
            />
          </div>

          <div className={"field-wrapper"}>
            <InputField
              errorClassName={"error"}
              required
              height={"42px"}
              name={"long_description"}
              label={t("Long Description")}
              error={
                formik.touched.long_description &&
                formik.errors.long_description
              }
              {...formik.getFieldProps("long_description")}
              labelClassName={"label"}
              maxLength={30}
              showCounting
              placeholder={t("Long Description")}
            />
          </div>
          <div className={"field-wrapper"}>
            <InputField
              errorClassName={"error"}
              required
              height={"42px"}
              name={"regular_price"}
              label={t("Listing Price")}
              error={
                formik.touched.regular_price && formik.errors.regular_price
              }
              {...formik.getFieldProps("regular_price")}
              labelClassName={"label"}
              maxLength={6}
              showCounting
              placeholder={t("Listing Price")}
            />
          </div>
          <div className={"field-wrapper"}>
            <InputField
              errorClassName={"error"}
              required
              height={"42px"}
              name={"selling_price"}
              label={t("Selling Price")}
              error={
                formik.touched.selling_price && formik.errors.selling_price
              }
              {...formik.getFieldProps("selling_price")}
              labelClassName={"label"}
              maxLength={6}
              showCounting
              placeholder={t("Selling Price")}
            />
          </div>

          <div className={"button-container"}>
            <Button
              type={"business"}
              label={t("Save")}
              borderRadius={"100px"}
              height={"40px"}
              htmlType={HtmlType.SUBMIT}
              loading={isLoading}
            />
          </div>
        </form>
      </div>
    </Container>
  )
}
export { ProductForm }
