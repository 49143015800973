import { API } from "@project/shared"
import {
  ITransactionHistory,
  TransactionHistory,
} from "../../interface/transaction-history"

export interface IReturnTransaction {
  data?: Array<TransactionHistory>
  total_count?: number
}

interface IDashboardValues {
  todays_registrant_number: string
  todays_topup_amount: string
  todays_trading_amount: string
}

export const fetchTransaction = async ({
  queryKey,
}): Promise<IReturnTransaction> => {
  const page = queryKey[1]
  const limit = queryKey[2]
  const searchQuery = queryKey[3]
  const facility_id = queryKey[4]

  const response = await API.get<any>(`/transactions`, {
    params: { page, limit, keyword: searchQuery, facility_id },
  })
  return response
}

export const transactionHistory = async ({
  queryKey,
}): Promise<IReturnTransaction> => {
  const page = queryKey[1]
  const limit = queryKey[2]
  const residentId = queryKey[3]

  const response = await API.get<any>(`/transactions/resident/${residentId}`, {
    params: { page, limit },
  })
  return response
}

export const fetchDashboardValues = ({
  queryKey,
}): Promise<IDashboardValues> => {
  const facility_id = queryKey[1]
  const params = {
    facility_id,
  }
  if (!facility_id) delete params.facility_id
  return API.get("/dashboard", {
    params: params,
  })
}
