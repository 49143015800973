import { API } from "@project/shared"
import { ProductListInterface } from "../../interface/product_list"

export interface IReturnProductList {
  data?: Array<ProductListInterface>
  total_count?: number
}

export const fetchProductList = async ({
  queryKey,
}): Promise<IReturnProductList> => {
  const page = queryKey[1]
  const limit = queryKey[2]
  const searchQuery = queryKey[3]
  const facility_id = queryKey[4]
  const res = await API.get<any>(`/products`, {
    params: {
      page,
      limit,
      keyword: searchQuery,
      facility_id,
    },
  })
  return res
}

export const createProductList = async (
  values
): Promise<ProductListInterface> => {
  return API.post(`/products`, values)
}

export const productListDetail = async ({ queryKey }): Promise<any> => {
  const id = queryKey[1]
  return API.get(`/products/${id}`)
}

export const updateProductListDetail = async (
  values: ProductListInterface,
  id: number
): Promise<ProductListInterface> => {
  return API.put(`/products/${id}`, values)
}

export const deleteProductList = async (id): Promise<ProductListInterface> => {
  return API.delete(`/products/${id}`)
}
