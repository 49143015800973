import {
  CsvDownload,
  DASHBOARD_TYPE,
  StyledPaginationComponent,
  Table,
  theme,
} from "@project/shared"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "react-query"
import styled from "styled-components"
import { transactionHistory } from "../../../services"

interface ITransactionHistoryProps {
  resident_id?: string
  name?: string
}
const Container = styled.div`
  margin-top: 30px;
  .container-item {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon {
      cursor: pointer;
    }
    p {
      ${theme.typography.header}
    }
    .csv-wrapper {
      a {
        cursor: pointer;
        font-size: 16px;
        color: ${theme.font.colorPrimary};
        text-decoration: underline;
      }
    }
  }
`
const StylePrice = styled.span<{ color: string }>`
  text-align: right;
  color: ${({ color }) => color};
`
const TransactionHistory: React.FC<ITransactionHistoryProps> = ({
  resident_id,
  name,
}) => {
  const { t } = useTranslation()
  const [page, setPages] = useState(1)
  const [pageCount, setPagecount] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  const handlePageChange = (page) => {
    setPages(+page)
  }

  const handlePageSizeChange = (_current, size) => {
    setPageSize(size)
  }

  const { isLoading, isFetching, data } = useQuery(
    ["transaction-history", page, pageSize, resident_id],
    transactionHistory,
    {
      refetchOnWindowFocus: false,
      select: (response) => {
        return {
          data: response?.data?.map((data) => {
            return {
              key: data.invoice_id,
              id: data.invoice_id,
              invoice_number: data.invoice_id || "-",
              pic: data?.facility?.representative_staff?.name || "管理者",
              product_name:
                data?.transaction_product_name || data?.description || "-",
              price: data.amount.toLocaleString() || "-",
              balance_after_purchase:
                data.balance_after_transaction.toLocaleString() || "-",
            }
          }),
          count: response?.total_count,
        }
      },
    }
  )
  useEffect(() => {
    setPagecount(data?.count || pageCount)
    // setPages(1)
  })

  const columns = [
    {
      title: t("Invoice number"),
      dataIndex: "invoice_number",
      key: "invoice_number",
      align: "center",
    },

    {
      title: t("Product name"),
      dataIndex: "product_name",
      key: "product_name",
      align: "center",
    },
    {
      title: t("PIC"),
      dataIndex: "pic",
      key: "pic",
      align: "center",
    },
    {
      title: t("Price"),
      dataIndex: "price",
      key: "price",
      align: "center",
      render: (data: string | number) => {
        return <span>{`¥${data.toLocaleString()}`}</span>
      },
    },
    {
      title: t("Balance after purchase"),
      dataIndex: "balance_after_purchase",
      key: "balance_after_purchase",
      align: "center",
      render: (data: string | number) => {
        return <span>{`¥${data.toLocaleString()}`}</span>
      },
    },
  ]
  const showData = columns.filter(
    (col) => col.dataIndex !== "id" && col.dataIndex !== "fullName"
  )
  return (
    <Container>
      <div className={"container-item"}>
        <p>{t("Transaction History")}</p>
        <div className={"csv-wrapper"}>
          <CsvDownload
            enabled={!!resident_id}
            primaryApiUrlEndpoint={`/transactions/csv/resident/${resident_id}`}
            secondaryApiUrlEndpoint={"/check_csv_creation"}
            downloadFileName={t("residentTransactionHistoryCSVFileName", {
              username: name,
            })}
            attachTimeStamp={false}
          />
        </div>
      </div>
      <Table
        type={DASHBOARD_TYPE.BUSINESS}
        dataSource={data?.data}
        columns={showData}
        pagination={false}
        loading={isLoading || isFetching}
      />
      <StyledPaginationComponent
        type={DASHBOARD_TYPE.BUSINESS}
        current={+page}
        hideOnSinglePage={false}
        total={pageCount}
        showSizeChanger={false}
        pageSize={10}
        onChange={handlePageChange}
        onShowSizeChange={handlePageSizeChange}
      />
    </Container>
  )
}

export default TransactionHistory
