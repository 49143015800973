import { Button, InputField, RadioButton, theme } from "@project/shared"
import { Spin, notification } from "antd"
import { FocusError } from "focus-formik-error"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import { UseMutateFunction, useMutation } from "react-query"
import styled from "styled-components"
import * as yup from "yup"

import { HtmlType, UserStatus } from "@project/shared/src/constants"
import { IUserManagement } from "../../../interface"
import { AxiosResponse } from "axios"
import { updateFacilityUser, updateUser } from "../../../services"
import { BusinessType } from "../../../utils/BusinessType"
import { queryClient } from "../../../pages/_app"

const Container = styled.div`
  .label {
    ${theme.typography.subLabel};
  }
  .error {
    ${theme.typography.subLabel};
    color: ${theme.font.colorAlert};
  }
  .field-wrapper {
    margin-bottom: 10px;
  }
  .button-container {
    margin-top: 30px;
    display: flex;
    justify-content: end;
  }
`
const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`
interface UserProps {
  id?: string
  isLoading?: boolean
  onSubmit?: UseMutateFunction<
    AxiosResponse<IUserManagement>,
    unknown,
    void | IUserManagement
  >
  currentUserData?: IUserManagement
  isUserLoggedIn?: boolean
  userType?: BusinessType
  updateButtonLoading?: boolean
}

export const ErrorLabel = styled.div<any>`
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
  .warning {
    ${theme.typography.subLabel};
    color: ${theme.font.colorWarning};
    margin-top: 2px;
    margin-left: 2px;
  }
  .validation-error {
    color: ${theme.font.colorAlert};
  }
`

const UserForm: React.FC<UserProps> = ({
  isLoading,
  onSubmit,
  id,
  currentUserData = {},
  isUserLoggedIn = false,
  userType,
  updateButtonLoading,
}) => {
  const { t } = useTranslation()

  const userDetails = {
    name: currentUserData?.representative_staff?.name || currentUserData?.name,
    email:
      currentUserData?.representative_staff?.email || currentUserData?.email,
    phone_number:
      currentUserData?.representative_staff?.phone_number ||
      currentUserData?.phone_number,
    password: "",
    status: currentUserData?.status,
    memo: currentUserData?.representative_staff?.memo || currentUserData?.memo,
  }

  const dataAvailability = Object.keys(currentUserData)?.length

  const validationSchema = yup.object().shape({
    name: yup.string().required(
      `${t("inputValidationMessage", {
        fieldName: `${t("Person incharge name")}`,
      })}`
    ),
    email: yup
      .string()
      .email(t("Email address format is incorrect"))
      .required(
        `${t("inputValidationMessage", {
          fieldName: `${t("Email address")}`,
        })}`
      ),
    password: dataAvailability
      ? yup
          .string()
          .matches(
            /^(?=.*[A-Z])(?=.*\d).{6,20}$/,
            t(
              "Password must be the combination of uppercase, lowercase & numbers with at least 6 and below 20 characters"
            )
          )
      : yup
          .string()
          .matches(
            /^(?=.*[A-Z])(?=.*\d).{6,20}$/,
            t(
              "Password must be the combination of uppercase, lowercase & numbers with at least 6 and below 20 characters"
            )
          )
          .required(
            `${t("inputValidationMessage", {
              fieldName: `${t("Password")}`,
            })}`
          ),
    phone_number: yup
      .string()
      .matches(
        /^\d{2,4}-?\d{2,4}-?\d{2,4}-?\d+$/,
        t("Phone number can only contain numbers (0-9) and hyphen (-)")
      )
      .test("length", t("Phone number is incorrect"), (val) => {
        if (!val) return true
        const stripped = val.replace(/-/g, "")
        return stripped.length >= 10 && stripped.length <= 12
      })
      .required(
        `${t("inputValidationMessage", {
          fieldName: `${t("Phone")}`,
        })}`
      ),

    status: userDetails
      ? yup.string().nullable()
      : yup.string().required(
          `${t("selectValidationMessage", {
            fieldName: `${t("Usage Status")}`,
          })}`
        ),
  })

  const initialValues: IUserManagement = dataAvailability
    ? userDetails
    : {
        name: "",
        email: "",
        phone_number: "",
        password: "",
        status: UserStatus.USING,
        memo: "",
      }

  const { mutate, isLoading: updateUserLoading } = useMutation(
    (data: IUserManagement) =>
      userType === BusinessType.FACILITY_ADMIN
        ? updateFacilityUser(
            {
              representative_email: data?.email,
              representative_name: data?.name,
              representative_phone_number: data?.phone_number,
              status: data?.status,
              address: currentUserData?.address,
              name: currentUserData?.name,
            },
            id
          )
        : updateUser(data, id),
    {
      onError: ({ response }) => {
        notification.error({
          message: response?.data?.error ?? t("Error Occurred"),
        })
      },
      onSuccess: () => {
        !isUserLoggedIn
          ? history.back()
          : queryClient.invalidateQueries("user-list")
        notification.success({
          message: t("User updated successfully"),
        })
      },
    }
  )

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      isUserLoggedIn
        ? (delete values.password, delete values.memo, mutate(values))
        : onSubmit(values)
    },
  })

  const options = [
    { label: t("using"), value: UserStatus.USING },
    { label: t("suspended"), value: UserStatus.SUSPENDED },
  ]

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Spin size={"default"} />
      </LoaderWrapper>
    )
  }

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <FocusError formik={formik} />
        <div className={"field-wrapper"}>
          <InputField
            label={t("Person in-charge name")}
            name={"personInchargeName"}
            error={formik.touched.name && formik.errors.name}
            {...formik.getFieldProps("name")}
            value={formik.values.name}
            onChange={(e: { target: { value: string } }) => {
              const value = e.target.value
              formik.setFieldValue("name", value)
            }}
            onBlur={formik.handleBlur}
            errorClassName={"error"}
            height={"42px"}
            required={dataAvailability ? false : true}
            labelClassName={"label"}
            maxLength={30}
            showCounting
          />
        </div>
        <div className={"field-wrapper"}>
          <InputField
            label={t("Phone number")}
            name={"phone_number"}
            errorClassName={"error"}
            height={"42px"}
            error={formik.touched.phone_number && formik.errors.phone_number}
            value={formik.values.phone_number}
            onChange={(e) => {
              const pattern = /^[0-9][0-9-]*$/
              const hyphens = (e.target.value.match(/-/g) || []).length
              const digits = e.target.value.length - hyphens <= 12
              if (
                (pattern.test(e.target.value) || e.target.value == "") &&
                hyphens <= 3 &&
                digits
              ) {
                formik.setFieldValue("phone_number", e.target.value)
              }
            }}
            onBlur={formik.handleBlur}
            labelClassName={"label"}
            required={dataAvailability ? false : true}
            maxLength={15}
            showCounting
            placeholder={"XXXX-XXXX-XXXX"}
          />
        </div>
        <div className={"field-wrapper"}>
          <InputField
            label={t("email_address")}
            errorClassName={"error"}
            height={"42px"}
            error={formik.touched.email && formik.errors.email}
            {...formik.getFieldProps("email")}
            required={dataAvailability ? false : true}
            labelClassName={"label"}
          />
        </div>
        {!dataAvailability && (
          <div className={"field-wrapper"}>
            <InputField
              type={"password"}
              errorClassName={"error"}
              height={"42px"}
              label={t("Password")}
              {...formik.getFieldProps("password")}
              required={true}
              labelClassName={"label"}
              maxLength={20}
              minLength={6}
            />
            <ErrorLabel>
              <span
                className={`warning ${
                  formik.errors.password &&
                  formik.touched.password &&
                  "validation-error"
                }`}
              >
                {t(
                  "Password must be the combination of uppercase, lowercase & numbers with at least 6 and below 20 characters"
                )}
              </span>
            </ErrorLabel>
          </div>
        )}
        {!isUserLoggedIn && (
          <>
            <div className={"field-wrapper"}>
              <RadioButton
                options={options}
                error={formik.errors.status}
                label={t("Usage Status")}
                {...formik.getFieldProps("status")}
                required
              />
            </div>
            <div className={"field-wrapper"}>
              <InputField
                type={"textarea"}
                rows={3}
                label={t("Memo")}
                labelClassName={"label"}
                {...formik.getFieldProps("memo")}
                errorClassName={"error"}
                maxLength={255}
                showCounting
              />
            </div>
          </>
        )}
        <div className={"button-container"}>
          <Button
            type={"business"}
            label={t(!dataAvailability ? "Save" : "Update")}
            loading={updateButtonLoading || updateUserLoading}
            borderRadius={"100px"}
            height={"40px"}
            htmlType={HtmlType.SUBMIT}
            disabled={!formik.dirty}
          />
        </div>
      </form>
    </Container>
  )
}
export { UserForm }
