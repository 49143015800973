import {
  Button,
  DatePicker,
  InputField,
  Select,
  getBalanceWithExpiryDate,
  theme,
} from "@project/shared"
import { Image, Spin } from "antd"
import { FocusError } from "focus-formik-error"
import { useFormik } from "formik"
import moment from "moment"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { UseMutateFunction } from "react-query"
import styled from "styled-components"
import * as yup from "yup"

import { ResidentInterface } from "../../../interface"
import EditOutlined from "../../../public/assets/icons/edit1.svg"

import TransactionHistory from "./transaction-history"
import { HtmlType, calculate_age } from "@project/shared/src/constants"

const Loader = styled.div`
  justify-content: center;
  display: flex;
`
const Container = styled.div`
  .content-item {
    border-bottom: solid 1px ${theme.border.colorSecondary};
    display: grid;
    grid-template-columns: 2fr 4fr 1fr;
    align-items: center;
    padding: 5px;
  }
  img {
    border-radius: 50%;
  }
  .ant-image-mask {
    border-radius: 50%;
  }
  .error {
    ${theme.typography.subLabel};
    color: ${theme.font.colorAlert};
  }
  .field-wrapper {
    margin-bottom: 10px;
    .error {
      color: ${theme.font.colorAlert};
      margin-left: 29%;
    }
  }
  .button-container {
    display: flex;
    justify-content: end;
  }
  .container {
    display: flex;
    justify-content: space-between;
    gap: 40px;
  }
  .content {
    width: 100%;
  }
  .label {
    margin-top: 10px;
  }
  .input {
    border: none;
    box-shadow: none;
  }
  .enabled {
    border: none;
    box-shadow: none;
    background-color: ${theme.background.colorSecondary};
  }
  .iconClass {
    justify-content: end;
    cursor: pointer;
    gap: 10px;
  }
`

const StyledInputField = styled(InputField)`
  border-bottom: solid 1px ${theme.border.colorSecondary};
  display: grid;
  grid-template-columns: ${(props) =>
    props.fullwidth == "true" ? "2fr 5fr" : "2fr 4fr 1fr"};
  align-items: center;
  padding: 5px;
`
const StyledSelectField = styled(Select)`
  .input .ant-select-selector {
    height: 42px;
    border-radius: 4px;
    border: none;
    background: ${theme.background.colorSecondary};
    justify-content: center;
    align-items: center;
  }
  .iconClass {
    margin-left: 54px;
    display: flex;
  }
`

interface TransactionProop {
  id?: string
  isLoading?: boolean
  residentDetailLoading?: boolean
  onSubmit?: UseMutateFunction<
    ResidentInterface,
    unknown,
    ResidentInterface | void
  >
  data?: ResidentInterface
}

const TransactionForm: React.FC<TransactionProop> = (TransactionProop) => {
  const { isLoading, residentDetailLoading, onSubmit, data, id } =
    TransactionProop
  const { t } = useTranslation()
  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .max(100, t("Limit exceed"))
      .required(
        `${t("apiInputFieldError", {
          fieldName: `${t("Full name")}`,
        })}`
      ),
    id: yup
      .string()
      .max(36, t("Limit exceed"))
      .required(
        `${t("apiInputFieldError", {
          fieldName: `${t("identification")}`,
        })}`
      ),
    furigana: yup.string().required(
      `${t("apiInputFieldError", {
        fieldName: `${t("furigana")}`,
      })}`
    ),

    address: yup
      .string()
      .max(100, t("Limit exceed"))
      .required(
        `${t("apiInputFieldError", {
          fieldName: `${t("Address")}`,
        })}`
      ),
    phone_number: yup
      .string()
      .matches(
        /^\d{2,4}-?\d{2,4}-?\d{2,4}-?\d+$/,
        t("Phone number can only contain numbers (0-9) and hyphen (-)")
      )
      .test("length", t("Phone number is incorrect"), (val) => {
        if (!val) return true
        const stripped = val.replace(/-/g, "")
        return stripped.length >= 10 && stripped.length <= 12
      }),
    village: yup
      .string()
      .max(20, t("Limit exceed"))
      .required(
        `${t("apiInputFieldError", {
          fieldName: `${t("Village name")}`,
        })}`
      ),
  })
  const [disable, setDisable] = useState({
    id: id ?? true,
    name: id ?? true,
    gender: id ?? true,
    birth_date: id ?? true,
    age: id ?? true,
    phone_number: id ?? true,
    address: id ?? true,
    villageName: id ?? true,
    registeredBy: id ?? true,
    furigana: id ?? true,
    balance: id ?? true,
    used_voucher: id ?? true,
    balance_with_expiry_date: id ?? true,
  })

  const onClick = (name) => {
    setDisable({ ...disable, [name]: false })
  }

  const initialValues = data
    ? data
    : ({
        name: "",
        gender: "",
        birth_date: "",
        phone_number: "",
        address: "",
        village: "",
        event_id: "",
        merchant: {},
        age: "",
        furigana: "",
        balance: "",
        used_voucher: "",
        balance_with_expiry_date: "",
      } as ResidentInterface)

  const formik = useFormik({
    validationSchema,
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      onSubmit({ ...values })
    },
  })
  const options = [
    { label: t("Male"), value: t("Male") },
    { label: t("Female"), value: t("Female") },
  ]
  if (isLoading) {
    return (
      <Loader>
        <Spin size={"default"} />
      </Loader>
    )
  }
  if (residentDetailLoading) {
    return (
      <div style={{ textAlign: "center", padding: 300 }}>
        <Spin />
      </div>
    )
  }
  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <FocusError formik={formik} />
        <div className={"container"}>
          <div className={"content"}>
            <div className={"field-wrapper"}>
              <Image
                width={100}
                height={100}
                src={data?.presigned_url}
                preview={{
                  mask: t("Preview"),
                }}
              />
              <StyledInputField
                label={t("ID")}
                errorClassName={"error"}
                {...formik.getFieldProps("id")}
                height={"42px"}
                inputClassName={disable ? "enabled" : "input"}
                labelClassName={"label"}
                placeholder={t("5b90b12e-28f7-4fe5-a795-865a484d4843")}
                value={formik.values.id}
                name={"id"}
                error={formik.touched.id && formik.errors.id}
                // icon={<EditOutlined onClick={() => onClick("id")} />}
                disabled={disable.id && true}
                // iconClassName={"iconClass"}
                maxLength={36}
                showCounting
              />
            </div>
            <div className={"field-wrapper"}>
              <StyledInputField
                label={t("Full name")}
                errorClassName={"error"}
                {...formik.getFieldProps("name")}
                height={"42px"}
                inputClassName={disable ? "enabled" : "input"}
                labelClassName={"label"}
                maxLength={45}
                placeholder={t("Please enter")}
                icon={<EditOutlined onClick={() => onClick("name")} />}
                disabled={disable.name && true}
                iconClassName={"iconClass"}
                showCounting
              />
              <p className={"error"}>{formik.errors.name}</p>
            </div>

            <div className={"field-wrapper"}>
              <StyledSelectField
                hideSuffixIcon={false}
                options={options}
                bordered={!disable.gender && true}
                label={t("Gender")}
                placeholder={t("Please select")}
                disabled={disable.gender && true}
                className={"content-item"}
                inputClassName={"input"}
                {...formik.getFieldProps("gender")}
                iconClassName={"iconClass"}
                onChange={(a) => {
                  formik.setFieldValue("gender", a)
                }}
                icon={<EditOutlined onClick={() => onClick("gender")} />}
              />
              <p className={"error"}>{formik.errors.name}</p>
            </div>

            <div className={"field-wrapper"}>
              <DatePicker
                hideSuffixIcon={disable.birth_date && true}
                label={t("Birthday")}
                borderRadius={"10px"}
                allowClear={false}
                format={"YYYY/MM/DD"}
                value={moment(formik.values.birth_date)}
                onChange={(value) => {
                  formik.setFieldValue(
                    "birth_date",
                    moment(value).format("YYYY-MM-DD")
                  )
                }}
                className={"content-item"}
                inputClassName={disable ? "enabled" : "input"}
                placeholder={t("Please enter")}
                icon={<EditOutlined onClick={() => onClick("birth_date")} />}
                disabled={disable.birth_date && true}
                iconClassName={"iconClass"}
              />
            </div>
            <div className={"field-wrapper"}>
              <StyledInputField
                value={calculate_age(formik.values.birth_date)}
                label={t("Age")}
                errorClassName={"error"}
                // {...formik.getFieldProps("age")}
                height={"42px"}
                inputClassName={disable ? "enabled" : "input"}
                labelClassName={"label"}
                placeholder={t("Please enter")}
                // icon={<EditOutlined onClick={() => onClick("age")} />}
                disabled={disable.age && true}
                iconClassName={"iconClass"}
              />
            </div>
            <div className={"field-wrapper"}>
              <StyledInputField
                label={t("phone number")}
                name={"phone_number"}
                type={"text"}
                errorClassName={"error"}
                height={"42px"}
                value={formik.values.phone_number}
                onChange={(e: { target: { value: string } }) => {
                  const pattern = /^[0-9][0-9-]*$/
                  const hyphens = (e.target.value.match(/-/g) || []).length
                  const digits = e.target.value.length - hyphens <= 12
                  if (
                    (pattern.test(e.target.value) || e.target.value == "") &&
                    hyphens <= 3 &&
                    digits
                  ) {
                    formik.setFieldValue("phone_number", e.target.value)
                  }
                }}
                onBlur={formik.handleBlur}
                maxLength={15}
                showCounting
                inputClassName={disable ? "enabled" : "input"}
                icon={<EditOutlined onClick={() => onClick("phone_number")} />}
                disabled={disable.phone_number && true}
                iconClassName={"iconClass"}
                placeholder={"XXXX-XXXX-XXXX"}
              />
              <p className={"error"}>{formik.errors.phone_number}</p>
            </div>
          </div>
          <div className={"content"}>
            <div className={"field-wrapper"}>
              <StyledInputField
                label={t("address")}
                errorClassName={"error"}
                height={"42px"}
                {...formik.getFieldProps("address")}
                maxLength={100}
                showCounting
                inputClassName={disable ? "enabled" : "input"}
                placeholder={t("Please enter")}
                icon={<EditOutlined onClick={() => onClick("address")} />}
                disabled={disable.address && true}
                iconClassName={"iconClass"}
              />
              <p className={"error"}>{formik.errors.address}</p>
            </div>
            <div className={"field-wrapper"}>
              <StyledInputField
                label={t("village name")}
                errorClassName={"error"}
                height={"42px"}
                {...formik.getFieldProps("village")}
                maxLength={20}
                showCounting
                inputClassName={disable ? "enabled" : "input"}
                placeholder={t("Please enter")}
                icon={<EditOutlined onClick={() => onClick("villageName")} />}
                disabled={disable.villageName && true}
                iconClassName={"iconClass"}
              />
              <p className={"error"}>{formik.errors.village}</p>
            </div>
            <div className={"field-wrapper"}>
              <StyledInputField
                label={t("Digital gift certificate balance")}
                errorClassName={"error"}
                height={"42px"}
                {...formik.getFieldProps(`${"balance"}`)}
                maxLength={45}
                value={`¥${Number(data.balance).toLocaleString()}`}
                showCounting
                inputClassName={disable ? "enabled" : "input"}
                placeholder={t("Please enter")}
                // icon={<EditOutlined onClick={() => onClick("balance")} />}
                disabled={true}
                iconClassName={"iconClass"}
              />
              {/* <p className={"error"}>{formik.errors.balance}</p> */}
            </div>

            <div className={"field-wrapper"}>
              <StyledInputField
                label={t("Used voucher")}
                errorClassName={"error"}
                height={"42px"}
                {...formik.getFieldProps("used_voucher")}
                value={`¥${Number(data.used_voucher)?.toLocaleString()}`}
                maxLength={45}
                showCounting
                inputClassName={disable ? "enabled" : "input"}
                placeholder={t("Please enter")}
                // icon={<EditOutlined onClick={() => onClick("used_voucher")} />}
                disabled={disable.used_voucher && true}
                iconClassName={"iconClass"}
              />
              {/* <p className={"error"}>{formik.errors.used_voucher}</p> */}
            </div>
            <div className={"field-wrapper"}>
              <StyledInputField
                label={t("Balance with Expiry Date")}
                errorClassName={"error"}
                height={"42px"}
                value={`¥${Number(
                  getBalanceWithExpiryDate(data.individual_balances)
                ).toLocaleString()}`}
                // {...formik.getFieldProps("balance_with_expiry_date")}
                maxLength={45}
                disabled={disable.balance_with_expiry_date && true}
                inputClassName={disable ? "enabled" : "input"}
                placeholder={t("Please enter")}
                iconClassName={"iconClass"}
              />
            </div>
          </div>
        </div>
        <div className={"button-container"}>
          <Button
            type={"business"}
            label={t("Save")}
            borderRadius={"120px"}
            height={"40px"}
            htmlType={HtmlType.SUBMIT}
          />
        </div>
        {/* <div>{submit}</div> */}
      </form>
      <TransactionHistory resident_id={id} name={data?.name} />
    </Container>
  )
}
export { TransactionForm }
