import { API } from "@project/shared"
import { ProductCategoriesInterface } from "../../interface/product_categories"

export interface IReturnProductCategories {
  data?: Array<ProductCategoriesInterface>
  count?: number
}

export const featchProductCategories = async ({
  queryKey,
}): Promise<IReturnProductCategories> => {
  const page = queryKey[1]
  const pageSize = queryKey[2]
  const facility_id = queryKey[3]
  const response = await API.get(`/product_categories`, {
    params: { page, pageSize, facility_id },
  })
  return response
}

export const createProductCategories = async (data): Promise<any> => {
  return API.post(`/product_categories`, data)
}

export const fetchProductCategoriesDetail = async ({
  queryKey,
}): Promise<ProductCategoriesInterface> => {
  const id = queryKey[1]
  const { data } = await API.get<ProductCategoriesInterface>(
    `/product_categories/${id}`
  )
  return data
}

export const updateProductCategoriesDetail = async (
  data,
  id
): Promise<ProductCategoriesInterface> => {
  return API.put(`/product_categories/${id}`, data)
}

export const deleteProductCategoriesDetail = async (
  id
): Promise<ProductCategoriesInterface> => {
  return API.delete(`/product_categories/${id}`)
}
