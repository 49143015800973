import React, { useCallback, useContext, useEffect, useState } from "react"
import { userPool } from "@project/shared"
import { SideBarComponent } from "@project/shared/src/components/organisms"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import User from "../../../public/assets/icons/user.svg"
import Home from "../../../public/assets/icons/home.svg"
import Product from "../../../public/assets/icons/product_management.svg"
import Logout from "../../../public/assets/icons/logout.svg"
import { AuthContext } from "../../../utils/AuthContext"
import { FileTextOutlined } from "@ant-design/icons"
import { BusinessType } from "../../../utils/BusinessType"
import { tenantDetail } from "../../../services"
import { useQuery } from "react-query"

interface SideBarProps {
  children?: any
}
const SideBar: React.FC<SideBarProps> = ({ children }) => {
  const { t } = useTranslation()
  const router = useRouter()
  const loggedInUser = userPool.getCurrentUser()
  const { user, setUser } = useContext(AuthContext)

  const { data: tenant } = useQuery(
    ["tenantDetail", user?.["custom:tenantId"]],
    () => tenantDetail(Number(user?.["custom:tenantId"])),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      select: (data) => {
        return data?.["data"]
      },
      enabled: !!user?.["custom:tenantId"],
    }
  )

  const handleSignOut = async () => {
    setUser(null)
    loggedInUser.signOut()
    localStorage.removeItem("indexed-value")
    router.push("/login")
  }

  const navItems = [
    {
      key: "home",
      icon: <Home />,
      label: t("Home"),
      onClick: () => router.push("/home"),
    },
    {
      key: "transaction-list",
      icon: <FileTextOutlined />,
      label: t("Transaction List"),
      onClick: () => router.push("/transaction-list"),
    },
    {
      key: "user",
      icon: <User />,
      label:
        user["cognito:groups"]?.[0] === BusinessType.FACILITY_ADMIN
          ? t("User management")
          : t("Information Managment"),
      onClick: () => router.push("/user"),
    },
    {
      key: "product",
      icon: <Product />,
      label: t("Product management"),
      onClick: () => router.push("/product"),
    },
    {
      key: "logout",
      icon: <Logout />,
      label: t("Logout"),
      onClick: handleSignOut,
    },
  ]
  return (
    <SideBarComponent tenant={tenant} navItems={navItems} type={"business"}>
      {children}
    </SideBarComponent>
  )
}
export { SideBar }
